* {
    margin: auto;
}


.scrollable::-webkit-scrollbar {
    display: none;
}

.scrollable {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-x: hidden;
}